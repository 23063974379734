.product-images-slider .swiper-button-next {
  color: white;
}
.product-images-slider .swiper-button-prev {
  color: white;
}
.product-images-slider .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: #ffffff;
  padding: 5px;
}
.product-images-slider .swiper-pagination-bullet-active {
  opacity: 1;
}
.product-images-slider .swiper-progress-bar {
  width: 600px;
  height: 4px;
  position: relative;
  margin: 20px auto;
  background: #eeeeee;
}
.product-images-slider .swiper-progress-bar .progress {
  height: inherit;
  left: 0;
  top: 0;
  position: absolute;
  background: white;
  z-index: 1;
}
.product-images-slider .swiper-progress-bar .progress-sections {
  left: 0;
  top: 0;
  position: absolute;
  height: inherit;
  width: inherit;
  z-index: 2;
  display: flex;
  flex-direction: row;
}
.product-images-slider .swiper-progress-bar .progress-sections span {
  flex: 1;
  height: inherit;
  border-right: 2px solid #eee;
}
.product-images-slider .swiper-progress-bar .progress-sections span:last-child {
  border-right-width: 0;
}

.representatives-info {
  display: flex;
  flex-direction: column;
  margin: 15px 5px;
}
.representatives-info h5 {
  font-size: small;
  padding: 4px 0 4px 0;
}
.representatives-info a {
  color: #44bb92;
  font-weight: 500;
  font-size: small;
}
.representatives-info a:hover {
  text-decoration-line: underline;
  color: #388e70;
}
.representatives-info a span {
  color: black;
  font-size: 16px;
  margin-right: 8px;
}/*# sourceMappingURL=App.css.map */