.product-images-slider {
  .swiper-button-next {
    color: white;
  }
  .swiper-button-prev {
    color: white;
  }

  .swiper-pagination-bullet {
    opacity: 0.5;
    background-color: #ffffff;
    padding: 5px;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-progress-bar {
    width: 600px;
    height: 4px;
    position: relative;
    margin: 20px auto;
    background: #eeeeee;
    .progress {
      height: inherit;
      left: 0;
      top: 0;
      position: absolute;
      background: white;
      z-index: 1;
    }
    .progress-sections {
      left: 0;
      top: 0;
      position: absolute;
      height: inherit;
      width: inherit;
      z-index: 2;
      display: flex;
      flex-direction: row;
      span {
        flex: 1;
        height: inherit;
        border-right: 2px solid #eee;
        &:last-child {
          border-right-width: 0;
        }
      }
    }
  }
}

.representatives-info {
  display: flex;
  flex-direction: column;
  margin: 15px 5px;

  h5 {
    font-size: small;
    padding: 4px 0 4px 0;
  }
  a {
    color: #44bb92;
    font-weight: 500;
    font-size: small;

    &:hover {
      text-decoration-line: underline;
      color: #388e70;
    }
    span {
      color: black;
      font-size: 16px;
      margin-right: 8px;
    }
  }
}
